import React from 'react';
import { styled } from 'stitches.config';
import { navigate } from 'gatsby';
import { getLocalStorage } from '@src/utils/localStorage';
import { WEB_PLAY_URL } from '@src/config';

export default function Play() {
  const hasWindow = typeof window !== 'undefined';

  const accessToken = hasWindow ? getLocalStorage('accessToken') : '';
  const refreshToken = hasWindow ? getLocalStorage('refreshToken') : '';

  const projectId = hasWindow
    ? window.location.pathname.split('/')[
        window.location.pathname.split('/').indexOf('projects') + 1
      ]
    : null;

  React.useEffect(() => {
    // 웹플레이 iframe에서 세션 정보가 없을 경우 프로젝트 상세 페이지로 이동
    window.addEventListener('message', (event) => {
      // 임시로 "no session data" 문자열로 처리
      if (event.data === 'no session data') {
        navigate(`/projects/${projectId}`, { replace: true });
      } else if (event.data === 'goBack') {
        navigate(`/projects/${projectId}`, { replace: true });
      } else if (event.data === 'gameComplete') {
        navigate(`/projects/${projectId}`, { replace: true });
      }
    });
  }, []);

  React.useEffect(() => {
    if (!accessToken) {
      navigate(`/projects/${projectId}`, { replace: true });
    }
  }, [accessToken, refreshToken]);

  return (
    <>
      <Container>
        <PlayArea>
          <Iframe
            src={`${WEB_PLAY_URL}/play?projectId=${projectId}${
              accessToken ? `&accessToken=${accessToken}` : ''
            }${refreshToken ? `&refreshToken=${refreshToken}` : ''}`}
            allow="clipboard-read; clipboard-write; web-share"
          />
        </PlayArea>
      </Container>
    </>
  );
}

const Container = styled('div', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
});

const PlayArea = styled('div', {
  width: '100%',
  maxWidth: '500px',
  height: '100%',
  maxHeight: '1104px',
  margin: '0 auto',
});

const Iframe = styled('iframe', {
  position: 'relative',
  width: '100%',
  height: '100%',
  margin: 0,
  padding: 0,
});
